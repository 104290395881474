import { MaskedDynamic } from 'imask';

export const dynamicMask = {
  mask: [
    { mask: '+7 ({0}00) 000 - 00 - 00' },
    { mask: '+7 ({9}00) 000 - 00 - 00' },
    { mask: '+7 ({2}00) 000 - 00 - 00' },
  ],
  dispatch: (appended: string, dynamicMasked: MaskedDynamic<unknown>) => {
    const number = (dynamicMasked.value + appended).replace(/\D/g, '');

    if (number.startsWith('2') || number.startsWith('72')) {
      return dynamicMasked.compiledMasks[2];
    }

    return dynamicMasked.compiledMasks[1];
  },
};